import { observable } from 'mobx'
import { Model, Store } from 'store/Base'

export class StockRuleset extends Model {
  static backendResourceName = 'stock_ruleset'

  @observable id = null
  @observable name = ''

  relations() {
    return {}
  }
}

export class StockRuleStore extends Store {
  static backendResourceName = 'stock_ruleset'
  Model = StockRuleset
}
